
$('.p-about .history-sec .item .topics').find('.close').on('click', function () {
  $(this).parent().stop().slideUp();
  $(this).parent().parent().removeClass('is_active');
});
$('.p-about .history-sec .item ').find('.year').on('click', function () {
  $(this).next().stop().slideToggle();
  $(this).parent().toggleClass('is_active');
});

