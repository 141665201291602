import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

$('.top-mv-slides').slick({
  arrows: false,
  dots: true,
  fade: true,
  infinite: true,
  easing: 'linear',
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});
/*
$('.top-mv-slides').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
  $('.mv-control li').removeClass('current');
  $('.mv-control').find('li').eq(nextSlide).addClass('current');
});
$('.mv-control li').on('click', function () {
  $(".current").removeClass("current");
  $(this).addClass("current");
  const slideIndex = $(this).index();
  $('.top-mv-slides').slick('slickGoTo', slideIndex, false);
});
*/
$('.top-obj-slides').slick({
  arrows: false,
  dots: false,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 7000,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});
$('.top-obj-slides2').slick({
  arrows: false,
  dots: false,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 14000,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});
$('.top-obj-slides3').slick({
  arrows: false,
  dots: false,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 9000,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});
$('.top-obj-slides4').slick({
  arrows: false,
  dots: false,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});
