

$(function () {
  var $elem = $('.posSwitch');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgA);
      } else {
        $this.prependTo(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});

$(function () {
  var $elem2 = $('.posSwitch2');
  var replaceWidth = 1001;
  function posSwitch2() {
    var windowWidth = parseInt($(window).width());
    $elem2.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgA);
      } else {
        $this.insertAfter(posFlgB);
      }
    });
  }
  posSwitch2();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch2();
    }, 100);
  });
});

$(function () {

  $('.slick-dots').appendTo('.mv');
});


