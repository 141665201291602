import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.config({ nullTargetWarn: false, });


//pc
$(".pc-menu .navs ul li").on('mouseenter', function () {
  let $this = $(this).find('.child-menu');
  gsap.to($this, {
    autoAlpha: 1,
    duration: 0.2
  });
}).on('mouseleave', function () {
  let $this = $(this).find('.child-menu');
  gsap.to($this, {
    autoAlpha: 0,
    duration: 0.2
  });
});

$(".footer-menu .navs ul li").on('mouseenter', function () {
  let $this = $(this).find('.child-menu');
  gsap.to($this, {
    autoAlpha: 1,
    duration: 0.2
  });
}).on('mouseleave', function () {
  let $this = $(this).find('.child-menu');
  gsap.to($this, {
    autoAlpha: 0,
    duration: 0.2
  });
});


//sp
let menuFlg = true;
let contactFlg = true;
$(document).on('click', '.menu-btn', function () {
  let $this = $(this);
  let $tar = $this.next();
  $this.find('.menu-trigger').stop().toggleClass('active');
  if (menuFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.3
    });
    $this.find('.word').text('CLOSE');
    $this.find('.word').addClass('active');
    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    menuFlg = false;
    if (contactFlg == false) {
      gsap.to('.sp-menu .inquiry-nav .child-menu', {
        autoAlpha: 0,
        duration: 0.3
      });
      contactFlg = true;

    }

  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    $(this).find('.word').text('MENU');
    $(this).find('.word').removeClass('active');
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    menuFlg = true;
  }
  return false;
});
$(document).on('click', '.sp-menu .inquiry-nav > ul > li > a', function () {
  let $this = $(this);
  let $tar = $this.next();
  if (contactFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.3
    });
    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    contactFlg = false;

    if (menuFlg == false) {
      gsap.to('.sp-menu .main-nav', {
        autoAlpha: 0,
        duration: 0.3
      });
      $('.menu-btn').find('.word').text('MENU');
      $('.menu-trigger').stop().toggleClass('active');
      menuFlg = true;
    }

  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    contactFlg = true;

  }
  return false;
});



$(".drop-down > a").on('click', function () {
  let $this = $(this);
  let $tar = $(this).next('.child-menu');
  $this.toggleClass('is_active');
  $tar.stop().slideToggle();
  return false;
});
